
body {
    overflow: hidden;
}

.content {
    max-height: 85vh;
    overflow-y: auto;
    padding: 0px 1.25vw;
    /*margin-top: 60px;*/
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.click {
    width: 30vw;
    height: 25vh;
    background: white;
    font-size: 5vh;
    border-radius: 15px;
    margin: 1.25vh auto;
    padding: .5vh;
    cursor: pointer;
}


.click:hover {
    background: #33cc33;
    transform: scale(1.12);
    transition-duration: 0.5s;
}

/*.click img {*/
/*  max-height: 70%;*/
/*  max-width: 45%;*/
/*  ;*/
/*}*/

.collapsible .content {
    padding: 6px;
    background-color: #eeeeee;
}
.collapsible .header {
    background-color: #dddddd;
    padding: 6px;
    cursor: pointer;
}